var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-2", attrs: { flat: "" } },
    [
      _c(
        "form-data",
        {
          ref: "absenceHourReference",
          attrs: { references: _vm.formReferences, model: _vm.hourObj },
          on: {
            "update:references": function ($event) {
              _vm.formReferences = $event
            },
          },
        },
        [_c("template", { slot: "section-top" })],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }